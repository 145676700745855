



























































import Vue from 'vue';
import Instant from '@/components/Labels/Instant.vue';
import { TransactionState, TransactionType } from '../model/enums';
import MoneyLabel from '@/components/Labels/MoneyLabel.vue';

interface OrderTransaction {
  id: string;
  type: TransactionType;
  firm: boolean;
  firmedAt: number;
  status: TransactionState;
}

interface OrderItem {
  id: string;
  transaction: OrderTransaction;
  info: {
    id: string;
    name: string;
    options: { id: string; name: string }[];
    product: {
      id: string;
      title: string;
    };
    host: {
      id: string;
      name: string;
    };
  };
  schedule: {
    id: string;
    term: {
      startedAt: number;
      endedAt: number;
      duration: number;
    };
  } | null;
  amount: {
    value: string;
    currencyCode: string;
  };
  remainedAmount: {
    value: string;
    currencyCode: string;
  };
  dependedItems: {
    id: string;
    transaction: OrderTransaction;
    count: number;
  }[];
  count: number;
  remains: number;
  units: {
    id: string;
    indexNo: number;
    replies: {
      id: string;
      indexNo: number;
      title: string;
      answer: string;
      selections: {
        value: string;
        label: string;
      }[];
    }[];
    status: string;
  }[];
}

export default Vue.extend({
  name: 'PurchaseItemInfoView',
  components: { Instant, MoneyLabel },
  props: {
    value: {
      type: Object,
    },
  },
  computed: {
    orderItem: function (): OrderItem {
      return this.value as OrderItem;
    },
    productTitle: function (): string {
      return this.orderItem.info?.product?.title || '';
    },
    itemName: function (): string {
      return this.orderItem.info?.name || '';
    },
    hostName: function (): string {
      return this.orderItem.info?.host?.name || '';
    },
    purchaseCount: function (): string {
      return this.orderItem.count?.toLocaleString() || '';
    },
    remains: function (): string {
      return this.orderItem.remains?.toLocaleString() || '';
    },
    isMobile(): boolean {
      return this.$store.state.isMobile;
    },
  },
});
