




































































































































import Vue from 'vue';
import { Select, Option } from 'element-ui';
import HostSearchInput from '@/components/Host/HostSearchInput.vue';
import Icon from '@/components/Icon/Icon.vue';
import { PurchaseItemFilterQuery } from '../model/PurchaseItemFilter';
import { Term } from '@/common/Term';
import FripButton from '../../../components/FripComponents/FripButton.vue';
import { isHostAdmin } from '@/env';
import VueRouter from 'vue-router';
import { toStringArray } from '@/common/util/toStringArray';
import { OptionType } from '@/common/model/Option';

export default Vue.extend({
  name: 'PurchaseSearchForm',
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    HostSearchInput,
    FripButton,
    Icon,
  },
  data() {
    const query = this.$route.query as PurchaseItemFilterQuery;
    let searchTarget = 'productTitle';
    let searchKeyword = '';

    if (query.productTitleLike) {
      searchTarget = 'productTitle';
      searchKeyword = query.productTitleLike;
    } else if (query.productIds) {
      searchTarget = 'productId';
      searchKeyword = toStringArray(query.productIds)?.join(',') || '';
    } else if (query.itemNameLike) {
      searchTarget = 'itemName';
      searchKeyword = query.itemNameLike;
    } else if (query.itemIds) {
      searchTarget = 'itemId';
      searchKeyword = toStringArray(query.itemIds)?.join(',') || '';
    } else if (query.scheduleIds) {
      searchTarget = 'scheduleId';
      searchKeyword = toStringArray(query.scheduleIds)?.join(',') || '';
    } else if (query.emailLike) {
      searchTarget = 'customerEmail';
      searchKeyword = query.emailLike;
    } else if (query.phoneLike) {
      searchTarget = 'phoneLike';
      searchKeyword = query.phoneLike;
    }

    return {
      isAdmin: !isHostAdmin(),
      searchHostId: toStringArray(query.hostIds)?.join(',') || '',
      searchTarget: searchTarget,
      searchKeyword: searchKeyword,
      totalCount: 0,
      searchTerm: {
        startedAt: query.startedAt ? Number(query.startedAt) : null,
        endedAt: query.endedAt ? Number(query.endedAt) : null,
      } as Term,
      excludedNothingLeft: false,
      includedCancel: false,
      visible: false,
      showFilterForm: false,
    };
  },
  computed: {
    searchKeywordPlaceholder(): string {
      if (
        this.searchTarget === 'productId' ||
        this.searchTarget === 'scheduleId' ||
        this.searchTarget === 'itemId'
      ) {
        return '아이디를 , 로 구분해 입력해주세요.';
      } else {
        return '검색어를 입력해주세요';
      }
    },
    iconName(): string {
      return this.showFilterForm ? 'iconTopArrow' : 'iconDownArrow';
    },
    isMobile(): boolean {
      return this.$store.state.isMobile;
    },
    productSearchOptions(): OptionType[] {
      return this.isAdmin
        ? [
            { value: 'productTitle', text: '상품명' },
            { value: 'productId', text: '상품ID' },
            { value: 'itemName', text: '옵션명' },
            { value: 'customerEmail', text: '크루 이메일' },
            { value: 'customerPhoneNumber', text: '크루 전화번호' },
            { value: 'scheduleId', text: '일정ID' },
            { value: 'itemId', text: '옵션ID' },
          ]
        : [
            { value: 'productTitle', text: '상품명' },
            { value: 'productId', text: '상품ID' },
            { value: 'itemName', text: '옵션명' },
          ];
    },
  },
  methods: {
    clear() {
      this.searchHostId = '';
      this.searchTarget = 'productTitle';
      this.searchKeyword = '';
      this.searchTerm = { startedAt: null, endedAt: null };

      this.$router.push({ query: undefined }).catch();
    },
    getIds(keyword: string): string[] {
      return keyword.split(',').map(i => i.trim());
    },
    buildFilter() {
      const filter: PurchaseItemFilterQuery = {};

      switch (this.searchTarget) {
        case 'productTitle':
          filter.productTitleLike = this.searchKeyword;
          break;
        case 'productId':
          filter.productIds = this.getIds(this.searchKeyword);
          break;
        case 'itemName':
          filter.itemNameLike = this.searchKeyword;
          break;
        case 'itemId':
          filter.itemIds = this.getIds(this.searchKeyword);
          break;
        case 'customerEmail':
          filter.emailLike = this.searchKeyword;
          break;
        case 'customerPhoneNumber':
          filter.phoneLike = this.searchKeyword;
          break;
        case 'scheduleId':
          filter.scheduleIds = this.getIds(this.searchKeyword);
          break;
      }

      if (this.searchTerm.startedAt) {
        filter.startedAt = this.searchTerm.startedAt.toString();
      }

      if (this.searchTerm.endedAt) {
        filter.endedAt = this.searchTerm.endedAt.toString();
      }

      filter.hostIds =
        this.searchHostId !== '' ? [this.searchHostId] : undefined;

      this.$router.push({ query: filter }).catch(err => {
        if (
          VueRouter.isNavigationFailure(
            err,
            VueRouter.NavigationFailureType.duplicated
          )
        ) {
          this.$emit('refresh', true);
        }
      });
    },
    showOrHideFilter() {
      this.showFilterForm = !this.showFilterForm;
    },
  },
});
