











































































































































import Vue from 'vue';
import PurchaseItemCard from './PurchaseItemCard.vue';

import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Button,
} from 'element-ui';
import Spinner from '@/components/Spinner.vue';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { PURCHASE_LIST_QUERY } from '../graphql/query';
import { isHostAdmin } from '@/env';
import {
  PurchaseItemFilter,
  PurchaseItemFilterQuery,
} from '../model/PurchaseItemFilter';
import { TransactionState, TransactionType } from '../model/enums';
import TransactionLabel from './controls/TransactionLabel.vue';
import Instant from '@/components/Labels/Instant.vue';
import MoneyLabel from '@/components/Labels/MoneyLabel.vue';
import { Term } from '@/common/Term';
import { toStringArray } from '@/common/util/toStringArray';
import { getConstants, getDomainName } from '@/env';

interface OrderTransaction {
  id: string;
  type: TransactionType;
  firm: boolean;
  firmedAt: number;
  status: TransactionState;
  order: {
    id: string;
  };
}

interface OrderItem {
  id: string;
  transaction: OrderTransaction;
  info: {
    id: string;
    name: string;
    options: { id: string; name: string }[];
    product: {
      id: string;
      title: string;
    };
    host: {
      id: string;
      name: string;
    };
    origin: {
      type: string;
    };
  };
  schedule: {
    id: string;
    term: {
      startedAt: number;
      endedAt: number;
      duration: number;
    };
  } | null;
  amount: {
    value: string;
    currencyCode: string;
  };
  purchaseCount: number;
  count: number;
  canceled: boolean;
  origin: {
    id: string;
    booking: {
      id: string;
    } | null;
  } | null;
  booking: {
    id: string;
  } | null;
}

interface Connection<T> {
  totalCount: number;
  edges: Edge<T>[];
}

interface Edge<T> {
  node: T;
}

export default Vue.extend({
  name: 'PurchaseSearchList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Button.name]: Button,
    Spinner,
    PurchaseItemCard,
    TransactionLabel,
    Instant,
    MoneyLabel,
  },
  data() {
    return {
      loading: 0,
      purchaseItems: [],
      totalCount: 0,
      size: 10,
      isAdmin: !isHostAdmin(),
      constants: getConstants(),
      domainName: getDomainName(),
    };
  },
  computed: {
    numberOfPages(): number {
      return Math.ceil(this.totalCount / this.size);
    },
    filter(): PurchaseItemFilter {
      const query = this.$route.query as PurchaseItemFilterQuery;

      var term: Term | null = null;
      if (query.startedAt || query.endedAt) {
        term = {
          startedAt: query.startedAt ? Number(query.startedAt) : null,
          endedAt: query.endedAt ? Number(query.endedAt) : null,
        };
      }
      return {
        transactionTerm: term,
        hostIds: toStringArray(query.hostIds) || null,
        productIds: toStringArray(query.productIds) || null,
        productTitleLike: query.productTitleLike || null,
        customerIds: query.customerIds || null,
        itemNameLike: query.itemNameLike ? query.itemNameLike : null,
        scheduleIds: query.scheduleIds ? query.scheduleIds : null,
        itemIds: query.itemIds ? query.itemIds : null,
        emailLike: query.emailLike ? query.emailLike : null,
        nameLike: query.nameLike ? query.nameLike : null,
        phoneLike: query.phoneLike ? query.phoneLike : null,
      };
    },
    isMobile(): boolean {
      return this.$store.state.isMobile;
    },
  },
  watch: {
    filter: {
      immediate: true,
      handler(val) {
        console.log(val);
      },
    },
  },
  methods: {
    linkGen(pageNum: number) {
      return {
        query: {
          ...this.$route.query,
          page: pageNum > 1 ? pageNum : undefined,
        },
      };
    },
    getOptionName(item: OrderItem): string {
      let name = '';
      if (item.schedule) {
        const startedAt = this.$moment(item.schedule.term.startedAt);
        const endedAt = this.$moment(item.schedule.term.endedAt);
        name =
          startedAt.format('YYYY-MM-DD HH:mm') +
          ' ~ ' +
          endedAt.format('YYYY-MM-DD HH:mm');
      } else {
        name = item.info.name;
      }

      if (this.isAdmin) {
        name += `(${item.schedule ? item.schedule.id : item.info.id})`;
      }

      return name;
    },
    getOrderItemId(item: OrderItem): string {
      return item.origin?.id || item.id;
    },
    getBookingId(item: OrderItem): string | null {
      return item.origin?.booking?.id || item.booking?.id || null;
    },
    onClickCell(item: OrderItem, cell: TableColumn): void {
      if (cell.label == '이동하기') {
        return;
      }
      this.$router.push(this.$wlink.purchase(this.getOrderItemId(item)));
    },
    refresh() {
      this.$apollo.queries.purchaseItems.refresh();
    },
    getChannelId(item: OrderItem): string {
      if (item.info.origin.type == 'FRIP') {
        return `O${item.transaction.order.id}`;
      }
      return `P${item.info.product.id}`;
    },
  },
  apollo: {
    purchaseItems: {
      query: PURCHASE_LIST_QUERY,
      variables(): { page: number; size: number; filter: PurchaseItemFilter } {
        return {
          page: Number(this.$route.query.page) || 1,
          size: this.size,
          filter: this.filter,
        };
      },
      error(e: ApolloError) {
        console.error(e);
      },
      // manual: true,
      result(
        result: ApolloQueryResult<{
          order: { purchaseItems: Connection<OrderItem> };
        }>
      ) {
        this.totalCount = result.data.order.purchaseItems.totalCount;
      },
      update: (data: { order: { purchaseItems: Connection<OrderItem> } }) =>
        data.order.purchaseItems.edges.map(edge => edge.node),
    },
  },
});
