import { CancelRequestParam } from '@/domain/order/model/CancelRequestParam';
import { ApolloClient, NormalizedCacheObject } from 'apollo-boost';
import {
  REQUEST_CANCEL_ORDER,
  RETRACT_CANCEL_ORDER,
} from '../graphql/mutation';
import { TransactionState } from '../model/enums';

export class PurchaseService {
  constructor(private readonly apollo: ApolloClient<NormalizedCacheObject>) {}

  public async requestCancel(
    param: CancelRequestParam
  ): Promise<{ id: string; status: TransactionState }> {
    const { data } = await this.apollo.mutate({
      mutation: REQUEST_CANCEL_ORDER,
      variables: {
        param: param,
      },
    });

    return data.requestCancelOrder as { id: string; status: TransactionState };
  }

  public async retractCancels(tids: string[]): Promise<void> {
    for (let i = 0; i < tids.length; i++) {
      await this.retractCancel(tids[i]);
    }
  }

  public async retractCancel(
    tid: string
  ): Promise<{ id: string; status: TransactionState }> {
    const { data } = await this.apollo.mutate({
      mutation: RETRACT_CANCEL_ORDER,
      variables: {
        tid: tid,
      },
    });

    return data.retractCancelOrderRequest as {
      id: string;
      status: TransactionState;
    };
  }
}
