







import Vue from 'vue';
import PurchaseSearchForm from '../components/PurchaseSearchForm.vue';
import PurchaseSearchList from '../components/PurchaseSearchList.vue';

export default Vue.extend({
  name: 'PurchaseListContainer',
  components: {
    PurchaseSearchForm,
    PurchaseSearchList,
  },
  methods: {
    onRefresh() {
      (this.$refs.list as any).refresh();
    },
  },
});
