
















import Vue from 'vue';
import PurchaseItemDetailView from '../components/PurchaseItemDetailView.vue';
import PurchaseItemInfoView from '../components/PurchaseItemInfoView.vue';
import PurchaseItemDetailSimpleTable from '../components/PurchaseItemDetailSimpleTable.vue';
import { PURCHASE_ITEM_QUERY } from '../graphql/query';

export default Vue.extend({
  name: 'PurchaseItemViewContainer',
  components: {
    PurchaseItemInfoView,
    PurchaseItemDetailView,
    PurchaseItemDetailSimpleTable,
  },
  data() {
    return {
      orderItemId: this.$route.params.id,
      orderItem: {},
    };
  },
  computed: {
    isMobile(): boolean {
      return this.$store.state.isMobile;
    },
  },
  apollo: {
    orderItem: {
      query: PURCHASE_ITEM_QUERY,
      variables(): { id: string } {
        return {
          id: this.orderItemId,
        };
      },
      update: data => data.order.orderItem,
    },
  },
  methods: {
    onRefresh(): void {
      this.$apollo.queries.orderItem.refresh();
    },
  },
});
