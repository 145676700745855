export enum CancelReasonKind {
  AUTO = 'AUTO',
  HOST = 'HOST',
  SYSTEM = 'SYSTEM',
  USER = 'USER',
}

export const CancelReasonKindLabels = [
  {
    value: CancelReasonKind.USER,
    label: '고객 변심',
  },
  {
    value: CancelReasonKind.AUTO,
    label: '최소인원 미달로 자동 취소',
  },
  {
    value: CancelReasonKind.HOST,
    label: '호스트 사정',
  },
  {
    value: CancelReasonKind.SYSTEM,
    label: '프립 시스템',
  },
];

export const CancelReasonKindLabelsForHost = [
  {
    value: CancelReasonKind.USER,
    label: '고객 변심',
  },
  {
    value: CancelReasonKind.HOST,
    label: '호스트 사정',
  },
];
