import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  CANCEL_ORDER_MUTATION,
  GENERATE_TRANSACTION_FILE,
  PROCESS_CANCEL_ORDER_MUTATION,
} from '../graphql/mutation';
import {
  ESTIMATE_CANCEL_QUERY,
  CHECK_EXISTENCE_OF_INVOICE_BY_ORDER_ITEM_UNIT_INFO,
} from '../graphql/query';
import { CancelRequestParam } from '../model/CancelRequestParam';
import { CancelResult } from '../model/CancelResult';
import { EstimatedCancelResult } from '../model/EstimatedCancelResult';
import { TransactionFilter } from '../model/TransactionFilter';

export class OrderService {
  constructor(private readonly apollo: ApolloClient<NormalizedCacheObject>) {}

  public async estimate(
    param?: CancelRequestParam,
    tid?: string
  ): Promise<EstimatedCancelResult> {
    const { data } = await this.apollo.query({
      query: ESTIMATE_CANCEL_QUERY,
      variables: {
        param: param,
        tid: tid,
      },
    });

    return data.order.estimateCancel as EstimatedCancelResult;
  }

  public async process(
    tid: string,
    param: CancelRequestParam
  ): Promise<CancelResult> {
    const { data } = await this.apollo.mutate({
      mutation: PROCESS_CANCEL_ORDER_MUTATION,
      variables: {
        tid: tid,
        param: param,
      },
    });

    return data.processCancelOrder as CancelResult;
  }

  public async cencel(param: CancelRequestParam): Promise<CancelResult> {
    const { data } = await this.apollo.mutate({
      mutation: CANCEL_ORDER_MUTATION,
      variables: {
        param: param,
      },
    });

    return data.cancelOrder as CancelResult;
  }

  public async generateFile(filter: TransactionFilter): Promise<FileResult> {
    const { data } = await this.apollo.mutate({
      mutation: GENERATE_TRANSACTION_FILE,
      variables: {
        filter: filter,
      },
    });
    console.log('generateFile:', data);
    return data.generateTransactionsFile;
  }

  public async checkExistenceOfInvoiceByOrderItemUnitInfo(
    orderItemId: string,
    indexNo: number
  ): Promise<boolean> {
    const { data } = await this.apollo.query({
      query: CHECK_EXISTENCE_OF_INVOICE_BY_ORDER_ITEM_UNIT_INFO,
      variables: {
        orderItemId,
        indexNo,
      },
    });

    return data.settlement.checkExistenceOfInvoiceByOrderItemUnitInfo;
  }
}

interface FileResult {
  success: boolean;
  message: string;
  url: string;
}
