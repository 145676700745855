













































import { CancelReasonKindLabelsForHost } from '@/domain/order/model/CancelReasonKind';
import { CancelReasonKind } from '@/domain/order/model/CancelReasonKind';
import { CancelRequestItemParam } from '@/domain/order/model/CancelRequestItemParam';
import { CancelRequestParam } from '@/domain/order/model/CancelRequestParam';
import { apolloClient } from '@/apolloClient';
import { PurchaseService } from '../service/PurchaseService';
import { ContainerMixin } from '@/common/mixin/containerMixin';

const service = new PurchaseService(apolloClient);

export default ContainerMixin.extend({
  name: 'CancelRequestPopup',
  data() {
    return {
      opened: false,
      orderId: '',
      itemParam: {} as CancelRequestItemParam,
      selectedKind: null,
      kinds: CancelReasonKindLabelsForHost,
      reason: '',
    };
  },
  computed: {
    countOfCancel: function (): number {
      return this.itemParam.indices?.length || 0;
    },
  },
  methods: {
    buidCancelRequestParam(): CancelRequestParam {
      return {
        orderId: this.orderId,
        items: [this.itemParam],
        forced: false,
        manualRefund: false,
        kind: this.selectedKind || CancelReasonKind.USER,
        fullyCancel: false,
        reason: this.reason,
        refunds: [],
        refundAccount: null,
      };
    },
    async onClickOK(): Promise<void> {
      console.log('onClickOK');

      if (!this.selectedKind) {
        return this.$modal.show({
          title: '주문 취소 신청 실패',
          message: '주문 취소 사유를 선택해 주세요.',
          type: 'warning',
        });
      }

      if (
        this.selectedKind === CancelReasonKind.HOST &&
        this.reason.trim().length === 0
      ) {
        return this.$modal.show({
          title: '주문 취소 신청 실패',
          message: '구체적인 사유를 최대 200자 이내로 입력해 주세요..',
          type: 'warning',
        });
      }

      if (
        this.selectedKind === CancelReasonKind.HOST &&
        this.reason.length > 200
      ) {
        return this.$modal.show({
          title: '주문 취소 신청 실패',
          message: '취소 사유는 200자를 넘을 수 없습니다.',
          type: 'warning',
        });
      }

      try {
        await service.requestCancel(this.buidCancelRequestParam());
        this.$modal.show(
          {
            title: '주문 취소 요청 성공',
            message: '정상 처리되었습니다.',
            type: 'primary',
          },
          () => {
            this.opened = false;
          }
        );
        this.$emit('success', true);
      } catch (err) {
        this.showErrorAlert(err, '주문 취소 요청 실패');
      }
    },
    open(orderId: string, param: CancelRequestItemParam): void {
      this.selectedKind = null;
      this.reason = '';
      this.orderId = orderId;
      this.itemParam = param;
      this.opened = true;
    },
  },
});
