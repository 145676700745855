export enum TransactionState {
  REQUESTED = 'REQUESTED',
  WAITING = 'WAITING',
  COMPLETED = 'COMPLETED',
  RETRACTED = 'RETRACTED',
  FAILED = 'FAILED',
}

export const TransactionStateLabels = [
  {
    value: TransactionState.REQUESTED,
    label: '신청',
  },
  {
    value: TransactionState.WAITING,
    label: '대기',
  },
  {
    value: TransactionState.COMPLETED,
    label: '완료',
  },
  {
    value: TransactionState.RETRACTED,
    label: '철회',
  },
  {
    value: TransactionState.FAILED,
    label: '실패',
  },
];

export enum TransactionType {
  PURCHASE = 'PURCHASE',
  CANCELLATION = 'CANCELLATION',
  RETURN = 'RETURN',
  EXCHANGE = 'EXCHANGE',
}

export const TransactionTypeLabels = [
  {
    value: TransactionType.PURCHASE,
    label: '구매',
  },
  {
    value: TransactionType.CANCELLATION,
    label: '취소',
  },
  {
    value: TransactionType.RETURN,
    label: '반품',
  },
  {
    value: TransactionType.EXCHANGE,
    label: '교환',
  },
];
