





























import Vue, { VueConstructor } from 'vue';
import gql from 'graphql-tag';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import { Edge } from '@frientrip/domain';

interface Host {
  id: string;
  name: string;
  status: HostState;
}

interface HostSearchParam {
  page?: number;
  size?: number;
  filter: HostFilter;
}

interface HostFilter {
  nameLike?: string;
  nameOrIdLike?: string;
  emailLike?: string;
}

enum HostState {
  WITHDRAWAL = 'WITHDRAWAL',
  APPLIED = 'APPLIED',
  ACTIVE = 'ACTIVE',
}

export default (
  Vue as VueConstructor<Vue & { $refs: { [key: string]: HTMLFormElement } }>
).extend({
  name: 'HostSearchInput',
  components: {
    VueBootstrapTypeahead,
  },
  model: {
    event: 'select',
  },
  props: {
    excludeWithdrawalHost: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
    },
  },
  data(): {
    name: string;
    hosts: Host[];
    selectedHostId: string;
    selectedHostName: string;
    selectedHost: Host;
    hostById: Host;
    excludeWithdrawalHostInSearchResult: boolean;
  } {
    return {
      name: '',
      hosts: [],
      selectedHostId: this.value ? (this.value as string) : '',
      selectedHostName: '',
      selectedHost: { id: '', name: '', status: HostState.APPLIED },
      hostById: { id: '', name: '', status: HostState.APPLIED },
      excludeWithdrawalHostInSearchResult: this.excludeWithdrawalHost,
    };
  },
  computed: {
    hostSearchResults(): Host[] {
      const results = [...this.hosts];

      if (this.name === this.hostById.id) {
        results.unshift(this.hostById);
      }

      return this.excludeWithdrawalHostInSearchResult
        ? results.filter(host => host.status !== HostState.WITHDRAWAL)
        : results;
    },
  },
  methods: {
    getHostName(name: string): void {
      this.name = name;
    },
    async selectHost(host: Host) {
      this.hosts = [];
      this.name = host.name;
      this.selectedHostId = host.id;
      this.selectedHostName = host.name;
      this.$emit('select', this.selectedHostId);
      await this.$apollo.queries.hostById.refetch();
      await this.$apollo.queries.hosts.refetch();
    },
    serializer(host: Host): string {
      if (isNaN(Number(this.name))) {
        return host.name;
      } else {
        return host.id;
      }
    },
  },
  apollo: {
    hosts: {
      query: gql`
        query hosts($page: Int, $size: Int, $filter: HostFilter) {
          hosts(page: $page, size: $size, filter: $filter) {
            edges {
              node {
                id
                name
                status
              }
            }
          }
        }
      `,
      variables(): HostSearchParam {
        return {
          page: 1,
          size: 10,
          filter: {
            nameOrIdLike: this.name,
          },
        };
      },
      update(data): Host[] {
        const hosts = data.hosts.edges.map((edge: Edge<Host>) => edge.node);
        return hosts;
      },
      skip(): boolean {
        return this.name === '';
      },
      debounce: 200,
    },
    hostById: {
      query: gql`
        query host($id: ID!) {
          host(id: $id) {
            id
            name
            status
          }
        }
      `,
      variables(): { id: string } {
        return {
          id: this.name,
        };
      },
      update: data => data.host,
      skip(): boolean {
        return this.name === '' || isNaN(Number(this.name));
      },
      debounce: 200,
    },
    selectedHost: {
      query: gql`
        query host($id: ID!) {
          host(id: $id) {
            id
            name
            status
          }
        }
      `,
      variables(): { id: string } {
        return {
          id: this.selectedHostId,
        };
      },
      result(result) {
        this.selectedHostName = result.data?.host?.name;
      },
      update: data => data.host,
      skip(): boolean {
        return this.selectedHostName !== '' || this.selectedHostId === '';
      },
      debounce: 200,
    },
  },
  watch: {
    value(newValue: string) {
      this.selectedHostId = newValue;
      if (newValue === '' || newValue === null) {
        // v-model이 바뀌어도 input value가 바뀌지 않아 수동으로 바꿔줌
        this.$refs.hostSearch.inputValue = '';
        this.selectedHostName = '';
      }
    },
  },
});
