




import Vue from 'vue';

interface Price {
  sale: string;
  retail: string;
  currenyCode: string; // TODO: 나중에 통화에 따라 금액 표시를 다르게 하도록 설정한다.
}

export default Vue.extend({
  name: 'PriceLabel',
  props: {
    price: {
      type: Object,
    },
  },
  computed: {
    money(): Price {
      return this.price as Price;
    },
    amountValue(): number {
      return Number(this.money?.sale || 0);
    },
    amountLabel(): string {
      return this.amountValue.toLocaleString();
    },
    textStyle(): string {
      return this.amountValue > 0 ? 'frip-primary' : 'frip-danger';
    },
  },
});
