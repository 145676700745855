import { gql } from 'apollo-boost';

export const REQUEST_CANCEL_ORDER = gql`
  mutation ReqeustCancelOrder($param: CancelRequestParamInput!) {
    requestCancelOrder(param: $param) {
      id
      status
    }
  }
`;

export const RETRACT_CANCEL_ORDER = gql`
  mutation RetractCancelOrderRequest($tid: ID!) {
    retractCancelOrderRequest(tid: $tid) {
      id
      status
    }
  }
`;
