




import Vue from 'vue';
import {
  TransactionState,
  TransactionStateLabels,
  TransactionType,
  TransactionTypeLabels,
} from '../../model/enums';

interface OrderTransaction {
  type: TransactionType;
  status: TransactionState;
}

export default Vue.extend({
  name: 'TransactionLabel',
  props: {
    transaction: {
      type: Object,
    },
  },
  computed: {
    data: function (): OrderTransaction {
      return this.transaction as OrderTransaction;
    },
    label: function (): string {
      const typeLabel = TransactionTypeLabels.find(
        i => i.value == this.data.type
      )?.label;
      const statusLabel = TransactionStateLabels.find(
        i => i.value == this.data.status
      )?.label;

      return `${typeLabel} ${statusLabel}`;
    },
  },
});
