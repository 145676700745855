

































import Vue from 'vue';
import TransactionLabel from './controls/TransactionLabel.vue';
import Instant from '@/components/Labels/Instant.vue';

export default Vue.extend({
  name: 'PurchaseItemCard',
  components: {
    TransactionLabel,
    Instant,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    optionName(): string {
      if (this.item.schedule) {
        const startedAt = this.$moment(this.item.schedule.term.startedAt);
        const endedAt = this.$moment(this.item.schedule.term.endedAt);
        // return (
        //   startedAt.format('YYYY-MM-DD HH:mm') +
        //   ' ~ ' +
        //   endedAt.format('YYYY-MM-DD HH:mm')
        // );
        return '일정';
      }

      return this.item.info.name;
    },
    bookingId(): string | null {
      return this.item.origin?.booking?.id || this.item.booking?.id || null;
    },
    orderItemId(): string {
      return this.item.origin?.id || this.item.id;
    },
  },
  methods: {
    onClickCard() {
      this.$router.push(this.$wlink.purchase(this.orderItemId));
    },
  },
});
