import gql from 'graphql-tag';

export const PURCHASE_LIST_QUERY = gql`
  query GetPurchaseItems(
    $filter: PurchaseItemFilterInput
    $page: Int
    $size: Int
  ) {
    order {
      purchaseItems(filter: $filter, page: $page, size: $size) {
        totalCount
        edges {
          node {
            id
            transaction {
              id
              type
              firm
              firmedAt
              status
              order {
                id
              }
            }
            info {
              id
              name
              options {
                id
                name
              }
              product {
                id
                title
              }
              host {
                id
                name
              }
              origin {
                type
              }
            }
            schedule {
              id
              term {
                startedAt
                endedAt
                duration
              }
            }
            amount {
              value
              currencyCode
            }
            origin {
              id
              booking {
                id
              }
            }
            count
            canceled
            booking {
              id
            }
          }
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  }
`;

export const PURCHASE_ITEM_QUERY = gql`
  query GetPurchaseItem($id: ID!) {
    order {
      orderItem(id: $id) {
        id
        transaction {
          id
          type
          firm
          firmedAt
          status
          order {
            id
            usedCouponCount
            items {
              id
              count
            }
          }
        }
        info {
          id
          name
          options {
            id
            name
          }
          price {
            sale
            retail
            discountRate
          }
          product {
            id
            title
          }
          host {
            id
            name
          }
          origin {
            type
          }
        }
        schedule {
          id
          term {
            startedAt
            endedAt
            duration
          }
        }
        amount {
          value
          currencyCode
        }
        remainedAmount {
          value
          currencyCode
        }
        dependedItems {
          id
          transaction {
            id
            type
            firm
            firmedAt
            status
          }
          count
          units {
            id
          }
        }
        count
        remains
        units(includeCancel: true) {
          id
          indexNo
          replies {
            id
            indexNo
            title
            answer
            selections {
              value
              label
            }
          }
          status
        }
      }
    }
  }
`;
