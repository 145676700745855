




































































import { ContainerMixin } from '@/common/mixin/containerMixin';
import { Table, TableColumn } from 'element-ui';
import { TransactionState, TransactionType } from '../model/enums';
import PriceLabel from '@/components/Labels/PriceLabel.vue';
import { Price } from '../../product/model/product/param/productSaleInfoParam';
import TransactionLabel from './controls/TransactionLabel.vue';
import { isHostAdmin } from '@/env';
import { CommonState } from '@frientrip/domain';
import CancelRequestPopup from './CancelRequestPopup.vue';
import { CancelRequestItemParam } from '@/domain/order/model/CancelRequestItemParam';
import { apolloClient } from '@/apolloClient';
import { PurchaseService } from '../service/PurchaseService';
import { OrderService } from '@/domain/order/service/OrderService';

interface OrderTransaction {
  id: string;
  type: TransactionType;
  firm: boolean;
  firmedAt: number;
  status: TransactionState;
  order: { id: string; usedCouponCount: number; items: OrderItem[] };
}

interface OrderItem {
  id: string;
  transaction: OrderTransaction;
  info: {
    id: string;
    price: Price;
  };
  dependedItems: OrderItemForCancel[];
  count: number;
  remains: number;
  units: OrderItemUnit[];
}

interface OrderItemUnit {
  id: string;
  indexNo: number;
  replies: Reply[];
  status: string;
}

interface OrderItemForCancel {
  id: string;
  transaction: OrderTransaction;
  count: number;
  units: { id: string }[];
}

interface Reply {
  id: string;
  title: string;
  answer: string;
  selections: { label: string; value: string }[];
}

interface Inquiry {
  question: string;
  answer: string;
}

const service = new PurchaseService(apolloClient);
const orderService = new OrderService(apolloClient);

export default ContainerMixin.extend({
  name: 'PurchaseItemDetailView',
  components: {
    PriceLabel,
    TransactionLabel,
    CancelRequestPopup,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      isHostAdmin: isHostAdmin(),
      selection: [] as OrderItemUnit[],
    };
  },
  computed: {
    orderItem: function (): OrderItem {
      return this.value as OrderItem;
    },
    items: function (): OrderItemForCancel[] {
      return this.orderItem.dependedItems || [];
    },
    units: function (): OrderItemUnit[] {
      return this.orderItem.units || [];
    },
    itemPrice: function (): Price | null {
      return this.orderItem.info?.price || null;
    },
    /**
     * TODO: 현행은 아이템별로 추가정보를 지정하지 않으므로 첫번째 구매 아이템의 추가정보를 대표로 표시하지만,
     * 추후에 아이템별로 추가정보를 지정하면 추가정보가 정확한 값이 아니므로 변경해야함
     */
    representativeInquiry(): Inquiry[] {
      const inquiries =
        this.units.length > 0
          ? this.units[0].replies.map(reply => this.getReply(reply))
          : [];
      return inquiries;
    },
  },
  methods: {
    handleSelectionChange(units: OrderItemUnit[]): void {
      console.log('handleSelectionChange:', units);
      this.selection = units;
    },
    getReply(reply: Reply): Inquiry {
      return {
        question: `Q. ${reply.title}`,
        answer: `A. ${reply.answer}${reply.selections
          .map(i => i.label)
          .join(', ')}`,
      };
    },
    getRelatedTransaction(unit: OrderItemUnit): OrderTransaction {
      const target = this.items.find(i => i.units.find(u => u.id == unit.id));
      return target ? target.transaction : this.orderItem.transaction;
    },
    async onClickCancelRequesting(): Promise<void> {
      console.log(`onClickCancelRequesting`);
      const itemTotalCount = this.calculateQuantity();

      if (this.selection.length == 0) {
        return this.$modal.show({
          title: '주문 취소 신청 실패',
          message: '취소할 대상을 선택 바랍니다.',
          type: 'warning',
        });
      }

      if (
        this.orderItem.transaction.order.usedCouponCount > 1 &&
        this.selection.length !== itemTotalCount
      ) {
        return this.$modal.show({
          title: '주문 취소 신청 실패',
          message: '중복 쿠폰 사용은 부분 취소를 할 수 없습니다.',
          type: 'warning',
        });
      }

      if (this.selection.some(i => i.status != CommonState.ACTIVE)) {
        return this.$modal.show({
          title: '주문 취소 신청 실패',
          message: '구매 완료 상태인 대상만 취소 가능 합니다.',
          type: 'warning',
        });
      }

      for (const item of this.selection) {
        if (
          await orderService.checkExistenceOfInvoiceByOrderItemUnitInfo(
            this.orderItem.id,
            item.indexNo
          )
        ) {
          return this.$modal.show({
            title: '주문 취소 신청 실패',
            message:
              '현재 결제 취소가 불가한 상태입니다. 이메일 (frip@frientrip.com) 또는 카카오톡 채널 (@프립호스트)로 문의해주세요',
            type: 'warning',
          });
        }
      }

      const param: CancelRequestItemParam = {
        orderItemId: this.orderItem.id,
        quantity: this.selection.length * -1, // 취소 수량은 음수로 설정
        indices: this.selection.map(i => i.indexNo),
      };

      const popup = this.$refs.CancelRequestPopup as InstanceType<
        typeof CancelRequestPopup
      >;
      popup?.open(this.orderItem.transaction.order.id, param);
    },
    onSuccessCancelRequest(): void {
      this.selection = [];
      this.$emit('refresh', true);
    },
    async onClickCancelRetraction(): Promise<void> {
      console.log(`onClickCancelRetraction`);
      if (this.selection.length == 0) {
        return this.$modal.show({
          title: '주문 취소 신청 실패',
          message: '철회할 대상을 선택 바랍니다.',
          type: 'warning',
        });
      }

      const transactions = this.selection.map(i =>
        this.getRelatedTransaction(i)
      );

      if (transactions.some(i => i.status != TransactionState.REQUESTED)) {
        return this.$modal.show({
          title: '주문 취소 철회 실패',
          message: '취소 신청 상태인 대상만 철회 가능 합니다.',
          type: 'warning',
        });
      }

      const tids = transactions.map(i => i.id);

      const uniqueTids = tids.filter(
        (item, index) => tids.indexOf(item) === index
      );

      try {
        await service.retractCancels(uniqueTids);
        this.$modal.show({
          title: '주문 취소 철회 성공',
          message: '정상 처리되었습니다.',
          type: 'primary',
        });
        this.$emit('refresh', true);
      } catch (err) {
        this.showErrorAlert(err, '주문 취소 철회 실패');
      }
    },
    calculateQuantity(): number {
      return this.orderItem.transaction.order.items.reduce(
        (total, originItem) => total + originItem.count,
        0
      );
    },
  },
});
